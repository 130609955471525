<template>
  <div class="confirmation-header-container">
    <ct-centered-spinner v-if="!loaded" />

    <template v-else>
      <h2 class="mt-2">
        Thank you for your order!
      </h2>
      <h4 class="confirmation-number">
        Your payment has been processed. Your order confirmation number is
        <span>
          {{ '#' + invoiceNumber }}
        </span>
      </h4>

      <template v-if="moreDetailsRequired">
        <p class="mt-4">
          To complete your filing, we need a few more details.
        </p>
        <div class="mt-5 w-100 d-flex flex-row justify-content-center">
          <button
            class="primary-btn"
            aria-label="get started button"
            @click="routeToGatherMoreDetails"
          >
            <b-spinner
              v-if="loadingLatestOrderRequiringVerification"
              small
              variant="light"
            />
            <span v-else>
              Let's get started!
            </span>
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "ConfirmationHeader",
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    invoice: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loaded: false,
      loadingLatestOrderRequiringVerification: false,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompanyId',
    ]),
    invoiceNumber() {
      return this.invoice.invoice_number
    },
    orderVerificationRequired() {
      return (this.invoice.order?.order_items?.some(oi =>
        oi.status === 'order-verification-required')
      ) || false
    },
    orderHasFilingItemRequiringAttention() {
      return (this.invoice.order?.order_items?.some(oi =>
        oi.type === 'filing' &&
        oi.status === 'awaiting-client-input')
      ) || false
    },
    moreDetailsRequired() {
      return this.orderVerificationRequired || this.orderHasFilingItemRequiringAttention
    },
  },
  async mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('verifyOrder', [
      'loadLatestOrderRequiringVerification',
    ]),
    async routeToGatherMoreDetails() {
      if (this.orderVerificationRequired) {
        this.loadingLatestOrderRequiringVerification = true

        // If another OrderVerificationRequired has already been set as current, replace it with the newly created one.
        await this.loadLatestOrderRequiringVerification({})
        this.loadingLatestOrderRequiringVerification = false
        this.logInteraction('redirect-verify-order')

        // Route to VTO
        this.$router.push({ name: 'verify-order' }).catch(() => {})
      } else if (this.orderHasFilingItemRequiringAttention) {
        this.logInteraction('redirect-order-requiring-attention')

        // Route to PostPaymentIRA if invoice has ORA
        this.$router.push({
          name: 'checkout-required-info',
          params: {
            companyId: this.currentCompanyId,
            invoiceId: this.invoice.id,
          },
        }).catch(()=>{})
      } else {
        this.logInteraction('redirect-order-requiring-attention')

        // Route to Pending Filings
        this.$router.push({ name: 'pendingFilings' }).catch(() => {})
      }
    },
    logInteraction(name) {
      this.$emit('logInteraction', 'button', 'redirect', name)
    },
  },
}
</script>

<style lang="scss" scoped>
$black: #231F20;

@mixin primary-buttons($background: $ct-ui-primary, $color: white) {
  background: $background;
  color: $color;
  border: 0.0625em solid $ct-ui-primary;
  border-radius: 0.25em;
  padding: 0.5em 1.875em;
  font-weight: 800;
  &:hover{
    border-color: $ct-ui-primary-dark;
    background: $ct-ui-primary-dark;
    color: white;
  }
}

h2,
h4,
p {
  color: $black;
}

h2 {
  font-size: 2.125em;
  font-weight: 700;
}

h4 {
  font-size: 1.25em;
  font-weight: 600;
}

p {
  font-size: 1.0em;
  font-weight: 400;
}

.confirmation-header-container {
  button.btn-primary {
    @include primary-buttons();
  }

  h4.confirmation-number {
    font-weight: 400;

    span {
      font-weight: 700;
    }
  }

  button.primary-btn {
    background: $ct-ui-primary;
    color: white;
    border: 0.0625em solid $ct-ui-primary;
    border-radius: 0.25em;
    padding: 0.5em 1.875em;
    font-weight: 700;

    &:hover{
      border-color: $ct-ui-primary-dark;
      background: $ct-ui-primary-dark;
      color: white;
    }
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.125em;
  }
}
</style>
