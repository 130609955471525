var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "confirmation-header-container" },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner")
        : [
            _c("h2", { staticClass: "mt-2" }, [
              _vm._v("\n      Thank you for your order!\n    "),
            ]),
            _c("h4", { staticClass: "confirmation-number" }, [
              _vm._v(
                "\n      Your payment has been processed. Your order confirmation number is\n      "
              ),
              _c("span", [
                _vm._v(
                  "\n        " + _vm._s("#" + _vm.invoiceNumber) + "\n      "
                ),
              ]),
            ]),
            _vm.moreDetailsRequired
              ? [
                  _c("p", { staticClass: "mt-4" }, [
                    _vm._v(
                      "\n        To complete your filing, we need a few more details.\n      "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-5 w-100 d-flex flex-row justify-content-center",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "primary-btn",
                          attrs: { "aria-label": "get started button" },
                          on: { click: _vm.routeToGatherMoreDetails },
                        },
                        [
                          _vm.loadingLatestOrderRequiringVerification
                            ? _c("b-spinner", {
                                attrs: { small: "", variant: "light" },
                              })
                            : _c("span", [
                                _vm._v(
                                  "\n            Let's get started!\n          "
                                ),
                              ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }